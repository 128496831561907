import React, { FC } from 'react'
import { Button, Modal, ModalProps } from 'antd'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { TooltipPlacement } from 'antd/es/tooltip'

// assets
import CloseIcon from '../assets/icons/close-icon-modal.svg?react'
import PopConfirmComponent from '../components/PopConfirmComponent'

export interface IConfirmModal extends Omit<ModalProps, 'okButtonProps' | 'cancelButtonProps'> {
	loading?: boolean
	disabled?: boolean
	okConfirm?: {
		title: string
		okText?: string
		placement?: TooltipPlacement
	}
	footerLayout?: 'row' | 'column'
	okButtonProps?: {
		id?: string
		className?: string
	}
	cancelButtonProps?: {
		id?: string
		className?: string
	}
}

const ConfirmModal: FC<IConfirmModal> = (props) => {
	const { loading, disabled, onOk, onCancel, children, okText, cancelText, okConfirm, footerLayout = 'row', footer, okButtonProps, cancelButtonProps, ...restProps } = props
	const [t] = useTranslation()

	const isColumnLayout = footerLayout === 'column'

	return (
		<Modal {...restProps} closeIcon={<CloseIcon />} onCancel={onCancel} onOk={onOk} footer={null}>
			{children}
			{footer !== null && (
				<div className={cx('flex gap-4 mt-6', { 'flex-col': isColumnLayout })}>
					<Button
						id={cancelButtonProps?.id}
						type={'dashed'}
						size={'middle'}
						className={cx(cancelButtonProps?.className, 'noti-btn', { 'w-full': isColumnLayout, 'w-1/2': !isColumnLayout })}
						htmlType={'button'}
						onClick={onCancel}
						disabled={disabled}
						loading={loading}
					>
						{cancelText || t('loc:Zrušiť')}
					</Button>
					{okConfirm ? (
						<PopConfirmComponent
							placement={okConfirm.placement || 'top'}
							title={okConfirm.title}
							onConfirm={onOk as any}
							okText={t('loc:Potvrdiť') || okConfirm.okText}
							allowedButton={
								<Button
									id={okButtonProps?.id}
									type={'primary'}
									size={'middle'}
									className={cx(okButtonProps?.className, 'noti-btn', { 'w-full': isColumnLayout, 'w-1/2': !isColumnLayout })}
									htmlType={'button'}
									disabled={disabled}
									loading={loading}
								>
									{okText || 'Ok'}
								</Button>
							}
						/>
					) : (
						<Button
							id={okButtonProps?.id}
							type={'primary'}
							size={'middle'}
							className={cx(props.okButtonProps?.className, 'noti-btn w-1/2')}
							htmlType={'button'}
							onClick={onOk}
							disabled={disabled}
							loading={loading}
						>
							{okText || 'Ok'}
						</Button>
					)}
				</div>
			)}
		</Modal>
	)
}

export default ConfirmModal
