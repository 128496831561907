/* eslint-disable import/no-cycle */
import dayjs from 'dayjs'
import axios from 'axios'

// types
import { ThunkDispatch } from 'redux-thunk'
import { CombinedState } from 'redux'
import { IResetStore } from '../generalTypes'
import {
	CalendarEvent,
	ICalendarEventsPayload,
	ICalendarEventDetailPayload,
	ICalendarDayEvents,
	ICalendarMonthlyViewEventsPayload,
	ICalendarEmployeesPayload,
	MonthlyViewEventData,
	RequestResponse,
	GetUrls,
	RequestParams
} from '../../types/interfaces'
import { ICalendarEventsQueryParams, ICalendarReservationsQueryParams, ICalendarShiftsTimeOffsQueryParams } from '../../types/schemaTypes'
import { RootState, ThunkResult } from '../index'

// enums
import { EVENTS, EVENT_DETAIL, SET_DAY_EVENTS, SET_IS_REFRESHING_EVENTS, EVENTS_MONTHLY_VIEW, SET_HIGHLIGHTED_CALENDAR_SHIFT_ID } from './calendarTypes'
import {
	CALENDAR_EVENTS_KEYS,
	CALENDAR_EVENT_TYPE,
	CANCEL_TOKEN_MESSAGES,
	CALENDAR_DATE_FORMAT,
	RESERVATION_FROM_IMPORT,
	CALENDAR_ALLOWED_RESERVATION_STATES,
	CALENDAR_MONTHLY_VIEW_EVENTS,
	MONTHLY_VIEW_EVENTS_CANCEL_TOKEN_KEYS
} from '../../utils/enums'

// utils
import { getReq } from '../../utils/request'
import { getDateTime, normalizeDataById } from '../../utils/helper'
import { compareAndSortDayEvents, getCountsAndDurationsCalendarEventId } from '../../pages/Calendar/calendarHelpers'

// redux
import { clearEvent } from '../virtualEvent/virtualEventActions'
import { setCalendarEmployees } from '../calendarEmployees/calendarEmployeesActions'
import { getEmployees } from '../employees/employeesActions'
import { isEnumValue } from '../../utils/intl'

// query params types
type CalendarEventsQueryParams = RequestParams<GetUrls['/api/b2b/admin/salons/{salonID}/calendar-events/']>['query']

// action types
export type ICalendarActions =
	| IResetStore
	| IGetCalendarEvents
	| IGetCalendarMonthlyViewEvents
	| IGetCalendarEventDetail
	| ISetIsRefreshingEvents
	| ISetDayEvents
	| ISetHighlightedCalendarShiftID

interface IGetCalendarEvents {
	type: EVENTS
	enumType: CALENDAR_EVENTS_KEYS
	payload: ICalendarEventsPayload
}

interface IGetCalendarMonthlyViewEvents {
	type: EVENTS_MONTHLY_VIEW
	payload: ICalendarMonthlyViewEventsPayload
}

interface IGetCalendarEventDetail {
	type: EVENT_DETAIL
	payload: ICalendarEventDetailPayload
}

interface ISetIsRefreshingEvents {
	type: typeof SET_IS_REFRESHING_EVENTS
	payload: boolean
}

interface ISetDayEvents {
	type: typeof SET_DAY_EVENTS
	payload: ICalendarDayEvents
}

interface ISetHighlightedCalendarShiftID {
	type: typeof SET_HIGHLIGHTED_CALENDAR_SHIFT_ID
	payload: string | null
}

const storedPreviousParams: any = {
	[CALENDAR_EVENTS_KEYS.RESERVATIONS]: {},
	[CALENDAR_EVENTS_KEYS.SHIFTS_TIME_OFFS]: {},
	[CALENDAR_MONTHLY_VIEW_EVENTS]: {}
}

export const getCalendarEventsCancelTokenKey = (enumType: CALENDAR_EVENTS_KEYS) => `calendar-events-${enumType}`
export const getCalendarMonthlyViewCancelTokenKey = (enumType: MONTHLY_VIEW_EVENTS_CANCEL_TOKEN_KEYS) => `calendar-events-${enumType}`

export const setDayEvents =
	(dayEvents: ICalendarDayEvents): ThunkResult<Promise<ICalendarDayEvents>> =>
	async (dispatch) => {
		dispatch({ type: SET_DAY_EVENTS, payload: dayEvents })
		return dayEvents
	}

export const clearEventDetail = (): ThunkResult<void> => (dispatch) => {
	dispatch({ type: EVENT_DETAIL.EVENT_DETAIL_LOAD_DONE, payload: { data: null } })
}

export const clearCalendarEvents =
	(enumType: CALENDAR_EVENTS_KEYS = CALENDAR_EVENTS_KEYS.EVENTS): ThunkResult<ICalendarEventsPayload> =>
	(dispatch) => {
		dispatch({ type: EVENTS.EVENTS_CLEAR, enumType })
		return { data: null }
	}

/**
 * pri partnerovi nezobrazujeme a nedotahujeme vymazanych zamestnancov
 * kedze zamestnancov pre kalendar dotahujeme v jednom EP spolu s eventami, tak pri prvom dotiahnuti este nevieme zistit, ci boli vymazani alebo nie
 * aby sme zbytocne nedotahovali eventy vymazanych zamestnancov, tak si potiahneme kolekciu zamestnancov zo samostatneho EP, na zaklade ktorej potom vieme vyfiltrovat id-cka vymazanych zamestnancov
 */

const getEmployeeIdsForRequest = async (
	employeeIDsQueryParams: string[] | undefined | null,
	calendarEmployees: ICalendarEmployeesPayload,
	salonID: string,
	getDeletedEmployeesEvents = true,
	dispatch: ThunkDispatch<CombinedState<RootState>, undefined, any>
) => {
	let employeeIDs = employeeIDsQueryParams

	// ak este nemam ulozenych zamestnancov v redux-e, znamena, ze sa jedna o prvy fetch
	if (!(calendarEmployees.data && salonID === calendarEmployees.salonID)) {
		const { data: employees } = await dispatch(getEmployees({ salonID, limit: 100 }))
		if (Array.isArray(employeeIDsQueryParams) && !getDeletedEmployeesEvents) {
			// vyfilturjeme vymazanych zamestnancov
			employeeIDs = employeeIDsQueryParams.filter((id) => !employees?.employees?.find((emp) => emp.id === id && !!emp.deletedAt))
		} else if (employeeIDsQueryParams === undefined) {
			// undefined => default stav = vyinicializujeme vsetkych nevymazanych zamestnancov
			employeeIDs = employees?.employees.reduce<string[]>((acc, emp) => {
				if (!emp.deletedAt) {
					return [...acc, emp.id]
				}
				return acc
			}, [])
		}
	} else if (employeeIDsQueryParams === undefined) {
		// undefined => default stav = vyinicializujeme vsetkych nevymazanych zamestnancov
		// v tomto pripade uz nemusime robit dalsi request na EP pre zamestnancov, pretoze uz mame v redux-e ulozenych calendarEmployees z prveho request-u
		employeeIDs = calendarEmployees.data.reduce<string[]>((acc, emp) => {
			if (!emp.isDeleted) {
				return [...acc, emp.id]
			}
			return acc
		}, [])
	}

	return employeeIDs
}

export const getCalendarEvents =
	(
		enumType: CALENDAR_EVENTS_KEYS = CALENDAR_EVENTS_KEYS.EVENTS,
		queryParams: ICalendarEventsQueryParams,
		clearVirtualEvent = true,
		storePreviousParams = true,
		eventsDayLimit = 0,
		getDeletedEmployeesEvents = true
	): ThunkResult<Promise<ICalendarEventsPayload>> =>
	async (dispatch, getState) => {
		if (storePreviousParams) {
			storedPreviousParams[enumType] = {
				queryParams,
				clearVirtualEvent,
				eventsDayLimit,
				getDeletedEmployeesEvents
			}
		}

		let payload = {} as ICalendarEventsPayload

		if (queryParams.categoryIDs === null && enumType === CALENDAR_EVENTS_KEYS.RESERVATIONS) {
			payload = dispatch(clearCalendarEvents(CALENDAR_EVENTS_KEYS.RESERVATIONS))
		} else {
			dispatch({ type: EVENTS.EVENTS_LOAD_START, enumType })

			try {
				const queryParamsEditedForRequest: CalendarEventsQueryParams = {
					categoryIDs: queryParams.categoryIDs || undefined,
					employeeIDs:
						(await getEmployeeIdsForRequest(
							queryParams.employeeIDs,
							getState().calendarEmployees.calendarEmployees,
							queryParams.salonID,
							getDeletedEmployeesEvents,
							dispatch
						)) || undefined,
					eventTypes: (queryParams.eventTypes as CALENDAR_EVENT_TYPE[]) || undefined,
					dateFrom: queryParams.start,
					dateTo: queryParams.end,
					reservationStates: queryParams.reservationStates || undefined
				}

				const { data } = await getReq('/api/b2b/admin/salons/{salonID}/calendar-events/', {
					params: { query: queryParamsEditedForRequest, path: { salonID: queryParams.salonID } },
					reqBody: {},
					customConfig: { allowAbort: true, abortSignalKey: getCalendarEventsCancelTokenKey(enumType) }
				})

				// employees sa mapuju do eventov
				const { data: calendarEmployees } = dispatch(setCalendarEmployees(data.employees, queryParams.salonID, getDeletedEmployeesEvents))

				const employees = normalizeDataById(calendarEmployees)

				const editedEvents = data.calendarEvents.reduce<CalendarEvent[]>((newEventsArray, event) => {
					const employee = employees[event.employee.id]

					if ((!employee || (employee && employee.isDeleted)) && !getDeletedEmployeesEvents) {
						return newEventsArray
					}

					const eventType = event.eventType === RESERVATION_FROM_IMPORT ? CALENDAR_EVENT_TYPE.RESERVATION : event.eventType

					if (!isEnumValue(eventType, CALENDAR_EVENT_TYPE)) {
						throw new Error(`Unsupported CALENDAR_EVENT_TYPE value - ${eventType}`)
					}

					const editedEvent: CalendarEvent = {
						...event,
						employee: employees[event.employee.id],
						startDateTime: getDateTime(event.start.date, event.start.time),
						endDateTime: getDateTime(event.end.date, event.end.time),
						isImported: event.eventType === RESERVATION_FROM_IMPORT,
						eventType
					}

					return [...newEventsArray, editedEvent]
				}, [])

				let eventsWithDayLimit: CalendarEvent[] = []
				if (eventsDayLimit) {
					const sortedEvents = [...editedEvents].sort((a, b) => {
						const aData = {
							start: a.startDateTime,
							end: a.endDateTime,
							id: a.id,
							employeeId: a.employee.id,
							eventType: a.eventType as CALENDAR_EVENT_TYPE,
							orderIndex: a.employee.orderIndex
						}
						const bData = {
							start: b.startDateTime,
							end: b.endDateTime,
							id: b.id,
							employeeId: b.employee.id,
							eventType: b.eventType as CALENDAR_EVENT_TYPE,
							orderIndex: b.employee.orderIndex
						}
						return compareAndSortDayEvents(aData, bData)
					})

					const dividedEventsIntoDays: ICalendarDayEvents = {}

					sortedEvents.forEach((event) => {
						if (dividedEventsIntoDays[event.start.date]) {
							dividedEventsIntoDays[event.start.date].push(event)
						} else {
							dividedEventsIntoDays[event.start.date] = [event]
						}
					})

					dispatch(setDayEvents(dividedEventsIntoDays))

					Object.values(dividedEventsIntoDays).forEach((day) => {
						eventsWithDayLimit = [...eventsWithDayLimit, ...day.slice(0, eventsDayLimit)]
					})
				}

				payload = {
					data: eventsDayLimit ? eventsWithDayLimit : editedEvents
				}

				dispatch({ type: EVENTS.EVENTS_LOAD_DONE, enumType, payload })
			} catch (err) {
				if (axios.isCancel(err) && (err as any)?.message === CANCEL_TOKEN_MESSAGES.CANCELED_DUE_TO_NEW_REQUEST) {
					// Request bol preruseny novsim request-om, tym padom chceme, aby loading state pokracoval
					dispatch({ type: EVENTS.EVENTS_LOAD_START, enumType })
				} else {
					dispatch({ type: EVENTS.EVENTS_LOAD_FAIL, enumType })
				}
				// eslint-disable-next-line no-console
				console.error(err)
			}
		}

		if (clearVirtualEvent) {
			dispatch(clearEvent())
		}

		return payload
	}

export const getCalendarReservations = (
	queryParams: ICalendarReservationsQueryParams,
	clearVirtualEvent?: boolean,
	storePreviousParams = true,
	eventsDayLimit = 0,
	getDeletedEmployeesEvents = true
): ThunkResult<Promise<ICalendarEventsPayload>> =>
	getCalendarEvents(
		CALENDAR_EVENTS_KEYS.RESERVATIONS,
		{
			...queryParams,
			eventTypes: [CALENDAR_EVENT_TYPE.RESERVATION, RESERVATION_FROM_IMPORT],
			reservationStates: CALENDAR_ALLOWED_RESERVATION_STATES
		},
		clearVirtualEvent,
		storePreviousParams,
		eventsDayLimit,
		getDeletedEmployeesEvents
	)

export const getCalendarShiftsTimeoff = (
	queryParams: ICalendarShiftsTimeOffsQueryParams,
	clearVirtualEvent?: boolean,
	storePreviousParams = true,
	eventsDayLimit = 0,
	getDeletedEmployeesEvents = true
): ThunkResult<Promise<ICalendarEventsPayload>> =>
	getCalendarEvents(
		CALENDAR_EVENTS_KEYS.SHIFTS_TIME_OFFS,
		{
			...queryParams,
			eventTypes: [CALENDAR_EVENT_TYPE.EMPLOYEE_SHIFT, CALENDAR_EVENT_TYPE.EMPLOYEE_TIME_OFF, CALENDAR_EVENT_TYPE.EMPLOYEE_BREAK]
		},
		clearVirtualEvent,
		storePreviousParams,
		eventsDayLimit,
		getDeletedEmployeesEvents
	)

type CountsAndDurationsCalendarEvents = RequestResponse<GetUrls['/api/b2b/admin/salons/{salonID}/calendar-events/counts-and-durations']>['calendarEvents']
type CountsAndDurationsCalendarEvent = NonNullable<CountsAndDurationsCalendarEvents['0']>['0']
type CountsAndDurationsCalendarEmployees = RequestResponse<GetUrls['/api/b2b/admin/salons/{salonID}/calendar-events/counts-and-durations']>['employees']

export const getCalendarMonthlyViewEvents =
	(
		queryParams: ICalendarEventsQueryParams,
		clearVirtualEvent?: boolean,
		storePreviousParams = true,
		getDeletedEmployeesEvents = true
	): ThunkResult<Promise<ICalendarMonthlyViewEventsPayload>> =>
	async (dispatch, getState) => {
		let payload = {} as ICalendarMonthlyViewEventsPayload

		if (storePreviousParams) {
			storedPreviousParams[CALENDAR_MONTHLY_VIEW_EVENTS] = {
				queryParams,
				clearVirtualEvent,
				getDeletedEmployeesEvents
			}
		}

		try {
			const commonQueryParams = {
				employeeIDs:
					(await getEmployeeIdsForRequest(
						queryParams.employeeIDs,
						getState().calendarEmployees.calendarEmployees,
						queryParams.salonID,
						getDeletedEmployeesEvents,
						dispatch
					)) || undefined,
				dateFrom: queryParams.start,
				dateTo: queryParams.end
			}

			const queryParamsReservations: CalendarEventsQueryParams = {
				...commonQueryParams,
				eventTypes: [CALENDAR_EVENT_TYPE.RESERVATION, RESERVATION_FROM_IMPORT],
				categoryIDs: queryParams.categoryIDs || undefined,
				reservationStates: queryParams.reservationStates || undefined
			}

			const queryParamsTimeOffs: CalendarEventsQueryParams = {
				...commonQueryParams,
				eventTypes: [CALENDAR_EVENT_TYPE.EMPLOYEE_TIME_OFF]
			}

			dispatch({ type: EVENTS_MONTHLY_VIEW.EVENTS_MONTHLY_VIEW_LOAD_START })

			const getTimeOffs = getReq('/api/b2b/admin/salons/{salonID}/calendar-events/counts-and-durations', {
				params: { query: queryParamsTimeOffs, path: { salonID: queryParams.salonID } },
				reqBody: {},
				customConfig: {
					allowAbort: true,
					abortSignalKey: getCalendarMonthlyViewCancelTokenKey(MONTHLY_VIEW_EVENTS_CANCEL_TOKEN_KEYS.TIME_OFFS)
				}
			})

			let reservations: CountsAndDurationsCalendarEvents = {}
			let timeOffs: CountsAndDurationsCalendarEvents = {}
			let employeesData: CountsAndDurationsCalendarEmployees = []

			// NOTE: tu je potrebne dat pozor aby sa tu kontrolovalo "queryParams.categoryIDs", pretoze ten moze byt aj null
			// upraveny objekt "queryParamsReservations" pre requesty uz maju "queryParamsReservations.categoryIDs" mozne hodnoty len string[] alebo undefined, pretoze len toto povoluje BE
			if (queryParams.categoryIDs === null) {
				const { data } = await getTimeOffs
				timeOffs = data.calendarEvents
				employeesData = data.employees
			} else {
				const data = await Promise.all([
					getReq('/api/b2b/admin/salons/{salonID}/calendar-events/counts-and-durations', {
						params: { query: queryParamsReservations, path: { salonID: queryParams.salonID } },
						reqBody: {},
						customConfig: {
							allowAbort: true,
							abortSignalKey: getCalendarMonthlyViewCancelTokenKey(MONTHLY_VIEW_EVENTS_CANCEL_TOKEN_KEYS.RESERVATIONS)
						}
					}),
					getTimeOffs
				])
				reservations = data[0].data.calendarEvents
				timeOffs = data[1].data.calendarEvents
				employeesData = data[0].data.employees
			}

			// employees sa mapuju do eventov
			const { data: calendarEmployees } = dispatch(setCalendarEmployees(employeesData, queryParams.salonID, getDeletedEmployeesEvents))
			const employees = normalizeDataById(calendarEmployees)

			const days = { ...reservations, ...timeOffs }

			const editedData = Object.entries(days).reduce<{ [key: string]: MonthlyViewEventData[] }>((daysAcc, [day]) => {
				const formattedDay = dayjs(day).format(CALENDAR_DATE_FORMAT.QUERY)
				const dayReservationsCountsAndDurations: CountsAndDurationsCalendarEvent[] = reservations[day] ?? []
				const dayTimeOffsCountsAndDurations: CountsAndDurationsCalendarEvent[] = timeOffs[day] ?? []

				const uniqueEmployeeIDs = [...(dayReservationsCountsAndDurations || []), ...(dayTimeOffsCountsAndDurations || [])].reduce<string[]>((acc, event) => {
					if (acc.find((employeeID) => event.employeeID === employeeID)) {
						return acc
					}
					return [...acc, event.employeeID]
				}, [])

				return {
					...daysAcc,
					[formattedDay]: Object.values(uniqueEmployeeIDs).reduce<MonthlyViewEventData[]>((acc, employeeID) => {
						const data: MonthlyViewEventData = {
							id: getCountsAndDurationsCalendarEventId(day, employeeID),
							employeeID,
							employee: employees[employeeID],
							reservationsCounts: dayReservationsCountsAndDurations.find((event) => event.employeeID === employeeID) || null,
							timeOffCounts: dayTimeOffsCountsAndDurations.find((event) => event.employeeID === employeeID) || null
						}

						return [...acc, data]
					}, [])
				}
			}, {})

			payload = {
				data: editedData
			}
			dispatch({ type: EVENTS_MONTHLY_VIEW.EVENTS_MONTHLY_VIEW_LOAD_DONE, payload })
		} catch (err) {
			if (axios.isCancel(err) && (err as any)?.message === CANCEL_TOKEN_MESSAGES.CANCELED_DUE_TO_NEW_REQUEST) {
				// Request bol preruseny novsim request-om, tym padom chceme, aby loading state pokracoval
				dispatch({ type: EVENTS_MONTHLY_VIEW.EVENTS_MONTHLY_VIEW_LOAD_START })
			} else {
				dispatch({ type: EVENTS_MONTHLY_VIEW.EVENTS_MONTHLY_VIEW_LOAD_FAIL })
			}
			// eslint-disable-next-line no-console
			console.error(err)
		}

		if (clearVirtualEvent) {
			dispatch(clearEvent())
		}

		return payload
	}

export const refreshEvents =
	(isMonthlyView = false): ThunkResult<Promise<void>> =>
	async (dispatch) => {
		const reservation = storedPreviousParams[CALENDAR_EVENTS_KEYS.RESERVATIONS]
		const shiftsTimeOff = storedPreviousParams[CALENDAR_EVENTS_KEYS.SHIFTS_TIME_OFFS]
		const monthlyViewEvents = storedPreviousParams[CALENDAR_MONTHLY_VIEW_EVENTS]

		try {
			dispatch({ type: SET_IS_REFRESHING_EVENTS, payload: true })

			if (isMonthlyView) {
				await dispatch(getCalendarMonthlyViewEvents(monthlyViewEvents.queryParams, monthlyViewEvents.clearEvent, true, monthlyViewEvents.getDeletedEmployeesEvents))
			} else {
				await Promise.all([
					dispatch(
						getCalendarReservations(reservation.queryParams, reservation.clearVirtualEvent, true, reservation.eventsDayLimit, reservation.getDeletedEmployeesEvents)
					),
					dispatch(
						getCalendarShiftsTimeoff(
							shiftsTimeOff.queryParams,
							shiftsTimeOff.clearVirtualEvent,
							true,
							shiftsTimeOff.eventsDayLimit,
							shiftsTimeOff.getDeletedEmployeesEvents
						)
					)
				])
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		} finally {
			dispatch({ type: SET_IS_REFRESHING_EVENTS, payload: false })
		}
	}

export const getCalendarEventDetail =
	(salonID: string, calendarEventID: string, displayNotification = true): ThunkResult<Promise<ICalendarEventDetailPayload>> =>
	async (dispatch) => {
		let payload = {} as ICalendarEventDetailPayload
		try {
			dispatch({ type: EVENT_DETAIL.EVENT_DETAIL_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/salons/{salonID}/calendar-events/{calendarEventID}', {
				params: { path: { calendarEventID, salonID } },
				reqBody: {},
				customConfig: { displayNotification }
			})

			payload = {
				data: {
					...data.calendarEvent,
					eventType: data.calendarEvent.eventType === RESERVATION_FROM_IMPORT ? CALENDAR_EVENT_TYPE.RESERVATION : data.calendarEvent.eventType,
					isImported: data.calendarEvent.eventType === RESERVATION_FROM_IMPORT
				}
			}

			dispatch({ type: EVENT_DETAIL.EVENT_DETAIL_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: EVENT_DETAIL.EVENT_DETAIL_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}

export const setHighlightedCalendarShiftID =
	(id: string | null): ThunkResult<void> =>
	(dispatch) => {
		dispatch({ type: SET_HIGHLIGHTED_CALENDAR_SHIFT_ID, payload: id })
	}
