// types
import { GetUrls, ISearchable, RequestResponse } from '../../types/interfaces'
import { REVIEWS } from './reviewsTypes'
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IGetReviewsQueryParams } from '../../types/schemaTypes'

// utils
import { getReq } from '../../utils/request'

export type IReviewsActions = IResetStore | IGetReviews

interface IGetReviews {
	type: REVIEWS
	payload: IReviewsPayload
}

export interface IReviewsPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/reviews/']>> {}

export const getReviews =
	(queryParams: IGetReviewsQueryParams): ThunkResult<Promise<IReviewsPayload>> =>
	async (dispatch) => {
		let payload = {} as IReviewsPayload
		try {
			dispatch({ type: REVIEWS.REVIEWS_LOAD_START })
			const { data } = await getReq('/api/b2b/admin/reviews/', {
				params: {
					query: {
						search: queryParams.search || undefined,
						page: queryParams.page,
						limit: queryParams.limit,
						order: queryParams.order,
						verificationStatus: queryParams.verificationStatus || undefined,
						deleted: queryParams.deleted,
						salonCountryCode: queryParams.salonCountryCode || undefined,
						toxicityScoreFrom: queryParams.toxicityScoreFrom,
						toxicityScoreTo: queryParams.toxicityScoreTo
					}
				},
				reqBody: {}
			})

			payload = {
				data
			}

			dispatch({ type: REVIEWS.REVIEWS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: REVIEWS.REVIEWS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}
